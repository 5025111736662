html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; /* Prevent scrolling */
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100vh;
  max-width: 100vw; /* Ensure it does not exceed screen width */
  overflow: hidden; /* Prevent scrolling */
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top {
  display: flex;
  height: 70%;
}

.top-left {
  border: 1px solid #ccc;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #999999; /* Light blue */
  box-sizing: border-box; /* Include padding and border in width */
  cursor: pointer;
}

.top-right {
  border: 1px solid #ccc;
  width: 25%;
  background-color: #010423; /* Light tan */
  box-sizing: border-box; /* Include padding and border in width */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.top-right h2 {
  color: #ffffff;
}

.bottom {
  border: 1px solid #ccc;
  height: 30%;
  display: flex;
  flex-direction: column;
  background-color: #010424; /* Light pink */
  box-sizing: border-box; /* Include padding and border in height */
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-history {
  flex: 1;
  overflow-y: auto;
  border-top: 1px solid #ccc;
}

.chat-input {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.chat-input input {
  width: 70%; /* Set width to 70% */
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box; /* Include padding in width */
  border-radius: 20px; /* Rounded edges */
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s;
}

.chat-input input:focus {
  border-color: #007bff; /* Change border color on focus */
}

.upload-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
